import {INTEGER, RECORD, STRING, UNKNOWN, guard} from '@acng/frontend-rubicon';
import {Store, createMap, get as getFromMap, remove, set, store} from '@acng/frontend-bounty/collection.js';
import {getLocale} from '../../locale/model/locale.js';
import {cdn} from '../../core/service/cdn.js';
import {Movie} from '../model/movie.js';
import {Response, calculateExpireTime} from '@acng/frontend-bounty/net/fetch.js';
import {MOVIE_DATA} from './guard.js';
import {deleteUnusedFields} from 'acng/core/model/gallery.js';
import {resolved} from '@acng/frontend-bounty/std/control.js';
import {now} from '@acng/frontend-bounty/std/date.js';
import {assign} from '@acng/frontend-bounty/object.js';
import {Infinity, isInstanceOf} from '@acng/frontend-bounty/std/value.js';
import {get as getFromApi} from '../../core/service/backend.js';

/**
 * @type {Map<Movie["id"], Promise<Movie>>}
 */
const idIndex = createMap();

/**
 * @type {Map<Movie["id"], number>}
 */
const expires = createMap();

/**
 * @type {Map<Movie["id"], Promise<Movie>>}
 */
const setIdIndex = createMap();

/**
 * @param {Movie["id"]} id
 * @returns {Promise<Movie>}
 */
export const get = async (id) => {
  const expire = getFromMap(expires, id);
  const movie = await store(idIndex, id, instantiateMovie);

  if (expire && expire < now()) {
    const data = fetchMovieData(id);

    assign(movie, data);
    handleMovieUpdate(movie);
  }

  return movie;
};

/**
 * @param {Gallery["set_id"]} setId
 */
export const getBySetId = Store(setIdIndex, async (setId) => {
  try {
    const movieId = await cdn(`/api/movie/idBySetId/${setId}`);
    ASSERT: guard(movieId, INTEGER, 'movie id by set_id');

    return await get(movieId);
  } catch (reason) {
    remove(setIdIndex, setId);

    throw reason;
  }
});

//export const list = async (limit, offset, filter, type) => {};

/**
 * @type {() => Promise<Record<string, string>>}
 */
export const getCategories = (() => {
  /**
   * @type {Record<string, string> | undefined}
   */
  let categories;

  const fetch = async () => {
    const data = await cdn(`/api/movie/categories?locale=${getLocale()}`);
    ASSERT: guard(data, RECORD(STRING, STRING));

    return data;
  };

  return async () => (categories ??= await fetch());
})();

/**
 * @param {Movie["id"]} id
 */
const instantiateMovie = async (id) => {
  try {
    const movie = new Movie(await fetchMovieData(id));

    handleMovieUpdate(movie);

    return movie;
  } catch (reason) {
    remove(idIndex, id);

    throw reason;
  }
};

/**
 * @param {Movie["id"]} id
 */
const fetchMovieData = async (id) => {
  try {
    const data = await cdn(`/api/movie/detail/${id}`, (response) => {
      const expire = calculateExpireTime(response);

      set(expires, id, expire);
    });
    ASSERT: {
      guard(data, RECORD(STRING, UNKNOWN));
      delete data.stream_id;
      deleteUnusedFields(data);
      guard(data, MOVIE_DATA, 'movie data');
    }

    return data;
  } catch (reason) {
    if (isInstanceOf(reason, Response)) {
      const {status, type} = reason;

      if (status == 404 || type == 'error') {
        const data = await getFromApi(`movie/blocked/${id}`);
        ASSERT: {
          guard(data, RECORD(STRING, UNKNOWN));
          delete data.stream_id;
          deleteUnusedFields(data);
          guard(data, MOVIE_DATA, 'movie data');
        }

        set(expires, id, Infinity);

        return data;
      }
    }

    throw reason;
  }
};

/**
 * @param {Movie} movie
 */
const handleMovieUpdate = (movie) => {
  set(setIdIndex, movie.set_id, resolved(movie));
};
